import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, ALGOLIA_SEARCH_INDEX } from './constants.js';
const aa = require("search-insights")

aa('init', {
    appId: ALGOLIA_APP_ID,
    apiKey: ALGOLIA_SEARCH_KEY
});

let userId = '';

if (typeof window.dataLayer !== 'undefined' && window.dataLayer.length > 0 && typeof window.dataLayer[0].customerNumber !== 'undefined') {
    userId = window.dataLayer[0].customerNumber;
} 

aa('set-user-token', userId);

const queryString = window.location.search;
const searchParams = new URLSearchParams(queryString);
const queryId = searchParams.get('queryId');

export const clickedObjectIDsAfterSearch = (eventName, itemCode, position) => {
    if (queryId) {
        aa('clickedObjectIDsAfterSearch', {
            // userToken: 'user-1', // required for Node.js            
            index: ALGOLIA_SEARCH_INDEX,
            eventName,
            queryID: queryId,
            objectIDs: [itemCode],
            positions: [+position]
        });
    }
}

export const convertedObjectIDsAfterSearch = (eventName, itemCode) => {
    if (queryId) {
        aa('convertedObjectIDsAfterSearch', {
            // userToken: 'user-1', // required for Node.js            
            index: ALGOLIA_SEARCH_INDEX,
            eventName,
            queryID: queryId,
            objectIDs: [itemCode]
        });
    }
}

export const clickedObjectIDs = (eventName, itemCode) => {
    aa('clickedObjectIDs', {
        // userToken: 'user-1', // required for Node.js            
        index: ALGOLIA_SEARCH_INDEX,
        eventName,
        objectIDs: [itemCode]
    });
}

