export const australianWineAU = {
	webdev: {
		appId: process.env.REACT_APP_SIT_AUS_ADC_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_AUS_ADC_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_AUS_ADC_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_AUS_ADC_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_AUS_ADC_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_AUS_ADC_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_AUS_ADC_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_AUS_ADC_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_AUS_ADC_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_AUS_ADC_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_AUS_ADC_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_AUS_ADC_ALGOLIA_QUERY_SUGGESTIONS,
	},
};


export const winePeopleAU = {
	webdev: {
		appId: process.env.REACT_APP_SIT_AUS_WP_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_AUS_WP_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_AUS_WP_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_AUS_WP_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_AUS_WP_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_AUS_WP_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_AUS_WP_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_AUS_WP_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_AUS_WP_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_AUS_WP_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_AUS_WP_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_AUS_WP_ALGOLIA_QUERY_SUGGESTIONS,
	},
};

export const virginWinesAU = {
	webdev: {
		appId: process.env.REACT_APP_SIT_AUS_VIR_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_AUS_VIR_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_AUS_VIR_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_AUS_VIR_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_AUS_VIR_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_AUS_VIR_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_AUS_VIR_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_AUS_VIR_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_AUS_VIR_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_AUS_VIR_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_AUS_VIR_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_AUS_VIR_ALGOLIA_QUERY_SUGGESTIONS,
	},
};

export const velocityWinesAU = {
	webdev: {
		appId: process.env.REACT_APP_SIT_AUS_VWS_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_AUS_VWS_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_AUS_VWS_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_AUS_VWS_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_AUS_VWS_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_AUS_VWS_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_AUS_VWS_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_AUS_VWS_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_AUS_VWS_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_AUS_VWS_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_AUS_VWS_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_AUS_VWS_ALGOLIA_QUERY_SUGGESTIONS,
	},
};