export const brandConfig = () => {
  const domain = window.location.hostname;
  let country = "";
  let language = "";
  let id = "";
  let parentId = "";

  if (domain.includes("localhost")) {
    country = "uk";
    language = "en";
    id = "law";
    return {
      country,
      language,
      id,
      parentId: id,
      imageType: ".png",
      imagePathForProducts: `/images/${country}/${language}/law`,
      defaultImagePathForProducts: `/images/default_images/default_bottle.png`,
      imageTypeForCases: ".png",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/default_images/default_case.png`,
    };
  }
  if (domain.includes("laithwaites.co.uk")) {
    country = "uk";
    language = "en";
    id = "law";
    return {
      country,
      language,
      id,
      parentId: id,
      imageType: ".png",
      imagePathForProducts: `/images/${country}/${language}/law`,
      defaultImagePathForProducts: `/images/${country}/${language}/law/default_images/default_bottle.png`,
      imageTypeForCases: ".png",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/${country}/${language}/law/default_images/default_case.png`,
    };
  }
  if (domain.includes("sundaytimeswineclub.co.uk")) {
    country = "uk";
    language = "en";
    id = "stw";
    return {
      country,
      language,
      id,
      parentId: id,
      imageType: ".png",
      imagePathForProducts: `/images/${country}/${language}/law`,
      defaultImagePathForProducts: `/images/${country}/${language}/law/default_images/default_bottle.png`,
      imageTypeForCases: ".png",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/${country}/${language}/law/default_images/default_case.png`,
    };
  }
  if (domain.includes("averys.com")) {
    country = "uk";
    language = "en";
    id = "avy";
    return {
      country,
      language,
      id,
      parentId: id,
      imageType: ".png",
      imagePathForProducts: `/images/${country}/${language}/law`,
      defaultImagePathForProducts: `/images/${country}/${language}/law/default_images/default_bottle.png`,
      imageTypeForCases: ".png",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/${country}/${language}/law/default_images/default_case.png`,
    };
  }
  if (domain.includes("bbcgoodfoodwineclub.com")) {
    country = "uk";
    language = "en";
    id = "bbc";
    parentId = "law";
    return {
      country,
      language,
      id,
      parentId,
      imageType: ".png",
      imagePathForProducts: `/images/${country}/${language}/law`,
      defaultImagePathForProducts: `/images/${country}/${language}/law/default_images/default_bottle.png`,
      imageTypeForCases: ".png",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/${country}/${language}/law/default_images/default_case.png`,
    };
  }

  if (
    domain.includes("laithwaiteswine.co.nz") ||
    domain.includes("laithwaites.co.nz")
  ) {
    return {
      country,
      language,
      imageType: "_T.jpg",
      imagePathForProducts: `/images/us/en`,
      defaultImagePathForProducts: `/images/au/common/new_default_bottle_thumb.png`,
      imageTypeForCases: "_L.jpg",
      imagePathForCases: `/images/nz/en/brands/law`,
      defaultImagePathForCases: `/images/au/common/new_default_mixed_case.png`,
    };
  }
  if (domain.includes("laithwaites.com.au")) {
    return {
      country,
      language,
      imageType: "_T.jpg",
      imagePathForProducts: `/images/us/en`,
      defaultImagePathForProducts: `/images/au/common/new_default_bottle_thumb.png`,
      imageTypeForCases: "_L.jpg",
      imagePathForCases: `/images/au/en/brands/wp`,
      defaultImagePathForCases: `/images/au/common/new_default_mixed_case.png`,
    };
  }
  if (domain.includes("winepeople.com.au")) {
    return {
      country,
      language,
      imageType: "_T.jpg",
      imagePathForProducts: `/images/us/en`,
      defaultImagePathForProducts: `/images/au/common/new_default_bottle_thumb.png`,
      imageTypeForCases: "_L.jpg",
      imagePathForCases: `/images/au/en/brands/wp`,
      defaultImagePathForCases: `/images/au/common/new_default_mixed_case.png`,
    };
  }
  if (domain.includes("theaustralianwine.com.au")) {
    return {
      country,
      language,
      imageType: "_T.jpg",
      imagePathForProducts: `/images/us/en`,
      defaultImagePathForProducts: `/images/au/common/new_default_bottle_thumb.png`,
      imageTypeForCases: "_L.jpg",
      imagePathForCases: `/images/au/en/brands/adc`,
      defaultImagePathForCases: `/images/au/common/new_default_mixed_case.png`,
    };
  }
  if (domain.includes("virginwines.com.au")) {
    return {
      country,
      language,
      imageType: "_T.jpg",
      imagePathForProducts: `/images/us/en`,
      defaultImagePathForProducts: `/images/au/common/new_default_bottle_thumb.png`,
      imageTypeForCases: "_L.jpg",
      imagePathForCases: `/images/au/en/brands/virgin`,
      defaultImagePathForCases: `/images/au/common/new_default_mixed_case.png`,
    };
  }
  if (domain.includes("velocitywinestore.com.au")) {
    return {
      country,
      language,
      imageType: "_T.jpg",
      imagePathForProducts: `/images/us/en`,
      defaultImagePathForProducts: `/images/au/common/new_default_bottle_thumb.png`,
      imageTypeForCases: "_L.jpg",
      imagePathForCases: `/images/au/en/brands/vws`,
      defaultImagePathForCases: `/images/au/common/new_default_mixed_case.png`,
    };
  }
  if (domain.includes("laithwaites.com")) {
    country = "us";
    language = "en";
    id = "law";
    return {
      country,
      language,
      id,
      parentId: id,
      imageType: ".png",
      imagePathForProducts: `/images/${country}/${language}/$`,
      defaultImagePathForProducts: `/images/us/common/default_bottle_thumb.png`,
      imageTypeForCases: ".png",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/us/en/common/default_mixed_case.jpg`,
    };
  }
  if (domain.includes("wsjwine.com")) {
    country = "us";
    language = "en";
    id = "wsj";
    return {
      country,
      language,
      id,
      parentId: id,
      imageType: "_L.jpg",
      imagePathForProducts: `/images/${country}/${language}/${id}`,
      defaultImagePathForProducts: `/images/us/common/default_bottle_thumb.png`,
      imageTypeForCases: ".jpg",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/${country}/${language}/law/default_images/default_case.png`,
    };
  }
  if (domain.includes("nprwineclub.org")) {
    country = "us";
    language = "en";
    id = "npr";
    return {
      country,
      language,
      id,
      parentId: id,
      imageType: "_L.jpg",
      imagePathForProducts: `/images/${country}/${language}/${id}`,
      defaultImagePathForProducts: `/images/us/common/default_bottle_thumb.png`,
      imageTypeForCases: ".jpg",
      imagePathForCases: `/images/${country}/${language}/law`,
      defaultImagePathForCases: `/images/${country}/${language}/law/default_images/default_case.png`,
    };
  }

  return;
};

const brand = brandConfig();

const errorPath = (event) => {
  event.currentTarget.src = `${brand.defaultImagePathForProducts}`;
};

const errorPathForCases = (event) => {
  event.currentTarget.src = `${brand.defaultImagePathForCases}`;
};

export const productItemTemplateWithImages = ({ hit, components, html }) => {
  return html`
    <div class="aa-ItemWrapper">
      <div class="aa-ItemContent">
        <div class="aa-ItemIcon aa-ItemIcon--alignTop results-image">
          ${hit.productType === "mixedcase" || hit.productType === "Case"
            ? html`
                <img
                  src="${brand.imagePathForCases}/product/${hit.itemCode}${brand.imageTypeForCases}"
                  alt="${hit.productName}"
                  onerror="${errorPathForCases}"
                />
              `
            : html`
                <img
                  src="${brand.imagePathForProducts}/product/${hit.itemCode}${brand.imageType}"
                  alt="${hit.productName}"
                  onerror="${errorPath}"
                />
              `}
        </div>
        <div class="aa-ItemContentBody">
          <div>
            ${components.Highlight({
              hit: hit,
              attribute: "productName",
            })}
            ${hit.vintage}
          </div>
        </div>
      </div>
    </div>
  `;
};
