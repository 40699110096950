import algoliasearch from 'algoliasearch';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';

import { ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, ALGOLIA_SEARCH_INDEX, ALGOLIA_QUERY_SUGGESTIONS } from './constants.js';
import { productItemTemplateWithImages } from './templates';

const appId = ALGOLIA_APP_ID;
const apiKey = ALGOLIA_SEARCH_KEY;
const searchClient = algoliasearch(appId, apiKey);

export const productsPlugin = {
    getSources({ query }) {
        if (query.length > 2) {
            return [
                {
                    sourceId: 'products',
                    getItems({ setContext, query }) {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: ALGOLIA_SEARCH_INDEX,
                                    query,
                                    params: {
                                        clickAnalytics: true,
                                        hitsPerPage: 10,
                                        attributesToSnippet: ['name:10', 'Description:20'],
                                        snippetEllipsisText: '…',
                                    },
                                },
                            ],
                            transformResponse({ hits, results }) {
                                setContext({ queryID: results[0].queryID });

                                /*
                                We can add a custom filter to remove items based on an attribute
                                const filter = hits?.[0].filter((hit) => hit.Country !== 'Great Britain');
                                */

                                return hits;
                            },
                        });
                    },
                    getItemUrl({ item }) {
                        return item.itemCode;
                    },
                    onSelect({ item, state }) {
                        window.location.href = `/product/${item.itemCode}?queryId=${item.__autocomplete_queryID}`;
                    },
                    templates: {
                        item({ item, components, state, html }) {
                            const countryRegionComma = item.Region ? ', ' : '';
                            return productItemTemplateWithImages({
                                hit: item,
                                insights: state.context.algoliaInsightsPlugin.insights,
                                components,
                                countryRegionComma,
                                html,
                            });
                        },
                        header() {
                            return 'Products';
                        }
                    },
                }
            ];
        }
    },
};

export const querySuggestionsPlugin = {
    getSources({ query }) {
        if (query.length > 2) {
            return [
                {
                    sourceId: 'querySuggestions',
                    getItems() {
                        return getAlgoliaResults({
                            searchClient,
                            queries: [
                                {
                                    indexName: ALGOLIA_QUERY_SUGGESTIONS,
                                    query,
                                    params: {
                                        clickAnalytics: true,
                                        hitsPerPage: 5,
                                    },
                                },
                            ],
                        });
                    },
                    onSelect({ item, state }) {
                        window.location.href = `/wines?Ntt=${item.query}&queryId=${item.__autocomplete_queryID}`;
                    },
                    templates: {
                        item({ item, components, html }) {
                            return html`<div class="aa-ItemWrapper">
						<div class="aa-ItemContent">
						<div class="aa-ItemContentBody">
								<div class="aa-ItemContentTitle">
									${components.Highlight({
                                hit: item,
                                attribute: 'query',
                            })}
								</div>
							</div>
						</div>
					</div>`;
                        },
                        header() {
                            return 'Popular searches';
                        }
                    }
                },
            ];
        } else {
            return [];
        }
    },
};

export const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
    transformSource({ source, state }) {
        if (!state.query) {
            return {
                ...source,
                templates: {
                    header() {
                        return 'Recent Searches';
                    },
                    item(params) {
                        const { html, item } = params;
                        return html`<a class="aa-ItemLink" href="/wines?Ntt=${item.label}">
                  ${source.templates.item(params).props.children}
                </a>`;
                    },
                },
            };
        }
    },
});

const predefinedItems = [
    {
        label: 'Customer service',
        url: '/customer-services',
    },
    {
        label: 'Delivery',
        url: '/customer-services/delivery-and-returns',
    },
    {
        label: 'Track your order',
        url: '/customer-services/orders#track-your-order',
    },
    {
        label: 'Black Friday offers',
        url: '/black-friday',
    },
    {
        label: 'Christmas offers',
        url: '/christmas',
    }
];

export const predefinedPlugin = {
    getSources() {
        return [
            {
                sourceId: 'predefinedPlugin',
                getItems({ query }) {
                    if (!query) {
                        return predefinedItems;
                    }
                    return predefinedItems.filter((item) =>
                        item.label.toLowerCase().includes(query.toLowerCase())
                    );
                },
                getItemUrl({ item }) {
                    return item.url;
                },
                templates: {
                    header({ items, html }) {
                        if (items.length === 0) {
                            return null;
                        }

                        return 'Useful links';
                    },
                    item({ item, html }) {
                        return html`<a class="quick-link" href="${item.url}">
                            <div class="aa-ItemIcon aa-ItemIcon--noBorder">
                                <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                >
                                <path
                                    d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"
                                />
                                <polyline points="15 3 21 3 21 9" />
                                <line x1="10" y1="14" x2="21" y2="3" />
                                </svg>
                            </div>
                            <div class="aa-ItemContent">
                                <div class="aa-ItemContentTitle">${item.label}</div>
                            </div>
                            </a>`;
                    },
                },
            },
        ];
    },
};