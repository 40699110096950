import { Component } from "react";
import { autocomplete } from "@algolia/autocomplete-js";
import {
  productsPlugin,
  querySuggestionsPlugin,
  recentSearchesPlugin,
} from "./plugins";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY,
  ALGOLIA_QUERY_SUGGESTIONS,
} from "./constants.js";

import { createAlgoliaInsightsPlugin } from "@algolia/autocomplete-plugin-algolia-insights";
import insightsClient from "search-insights";
import { getCountry } from "./constants.js";
// Events
import {
  clickedObjectIDsAfterSearch,
  convertedObjectIDsAfterSearch,
} from "./events";

class AutoComplete extends Component {
  constructor() {
    super();

    // Get all the buttons that needs to pass Algolia events
    Array.from(document.querySelectorAll(".js-algolia-click-objects")).forEach(
      (btn) => {
        btn.addEventListener("click", (e) => {
          const eventName = e.target.getAttribute("data-event-name");
          const itemCode = e.target.getAttribute("data-itemcode");
          const position = e.target.getAttribute("data-position");
          clickedObjectIDsAfterSearch(eventName, itemCode, position);
        });
      }
    );

    /* We add an event called 'event-algolia-converted' to add add to cart function
		in order to pass the itemcode, if the event is fired, we capture the event and fired
		the Algolia conversion event */
    document.addEventListener("event-algolia-converted", (e) => {
      convertedObjectIDsAfterSearch(
        "Algolia Added to Basket",
        e.detail.itemcode
      );
    });

    const appId = ALGOLIA_APP_ID;
    const apiKey = ALGOLIA_SEARCH_KEY;

    let userToken = "";

    if (window.dataLayer?.[0]?.customerNumber) {
      userToken = window.dataLayer[0].customerNumber;
    } else if (window.dataLayer?.bid) {
      userToken = window.dataLayer?.bid;
    }

    // Insights
    if (userToken) {
      insightsClient("init", { appId, apiKey });
      insightsClient("setUserToken", userToken);
    } else {
      insightsClient("init", { appId, apiKey, useCookie: true });
    }

    const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({
      insightsClient,
      onSelect({ insights, insightsEvents }) {
        const events = insightsEvents.map((insightsEvent) => {
          switch (insightsEvent.index) {
            case ALGOLIA_QUERY_SUGGESTIONS: {
              return {
                ...insightsEvent,
                eventName: "Suggestion Selected from Autocomplete",
              };
            }
            default: {
              return {
                ...insightsEvent,
                eventName: "Item Selected from Autocomplete",
              };
            }
          }
        });
        insights.clickedObjectIDsAfterSearch(...events);
      },
    });

    const country = getCountry();

    /**
     *
     * @param {Array} products
     * @param {Component} html
     * @param {String} query
     * @returns
     */
    const viewAllItemsLink = (products, html, query) => {
      if (products && products.items && products.items.length > 9) {
        return html`
          <div class="view-all-items-container">
            <a href="/wines?Ntt=${query}">See all results</a>
          </div>
        `;
      }
    };

    autocomplete({
      detachedMediaQuery: "none",
      container: "#algolia-autocomplete",
      placeholder: country !== "UK" ? "Search" : "Search",
      openOnFocus: country === "UK" ? true : false,
      plugins: [
        algoliaInsightsPlugin,
        querySuggestionsPlugin,
        productsPlugin,
        recentSearchesPlugin,
        // predefinedPlugin
      ],
      onSubmit({ state }) {
        if (state.query) {
          return (window.location.href = `/wines?Ntt=${state.query}&queryId=${state.context.queryID}`);
        }
        return;
      },
      render({ elements, render, html, state }, root) {
        // This is based on the sourceID in plugins
        const { recentSearchesPlugin, querySuggestions, products } = elements;

        // Get the item length for the product index, so we can ad a link if item
        // length is greater than 10
        const productsArray = state.collections.find(
          (item) => item.source.sourceId === "products"
        );

        render(
          html`<div class="aa-PanelLayout aa-Panel--scrollable">
            <div class="ui-container">
              <div class="ui-plugins">
                ${querySuggestions}
                <div id="recent-searches">${recentSearchesPlugin}</div>
              </div>
              <div class="ui-products">
                <div>${products}</div>
                <div>${viewAllItemsLink(productsArray, html, state.query)}</div>
              </div>
            </div>
          </div>`,
          root
        );
      },
    });
  }
  render() {
    return;
  }
}

export default AutoComplete;
