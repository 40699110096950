export const laithwaitesNZ = {
	webdev: {
		appId: process.env.REACT_APP_SIT_NZ_LAW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_NZ_LAW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_NZ_LAW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_NZ_LAW_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_NZ_LAW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_NZ_LAW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_NZ_LAW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_NZ_LAW_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_NZ_LAW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_NZ_LAW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_NZ_LAW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_NZ_LAW_ALGOLIA_QUERY_SUGGESTIONS,
	},
};