export const laithwaitesUK = {
	webdev: {
		appId: process.env.REACT_APP_SIT_UK_LAW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_UK_LAW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_UK_LAW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_UK_LAW_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_UK_LAW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_UK_LAW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_UK_LAW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_UK_LAW_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_UK_LAW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_UK_LAW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_UK_LAW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_UK_LAW_ALGOLIA_QUERY_SUGGESTIONS,
	},
};

export const sundaytimesUK = {
	webdev: {
		appId: process.env.REACT_APP_SIT_UK_STW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_UK_STW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_UK_STW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_UK_STW_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_UK_STW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_UK_STW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_UK_STW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_UK_STW_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_UK_STW_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_UK_STW_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_UK_STW_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_UK_STW_ALGOLIA_QUERY_SUGGESTIONS,
	},
};

export const averysUK = {
	webdev: {
		appId: process.env.REACT_APP_SIT_UK_AVY_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_UK_AVY_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_UK_AVY_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_UK_AVY_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_UK_AVY_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_UK_AVY_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_UK_AVY_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_UK_AVY_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_UK_AVY_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_UK_AVY_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_UK_AVY_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_UK_AVY_ALGOLIA_QUERY_SUGGESTIONS,
	},
};

export const bbcUK = {
	webdev: {
		appId: process.env.REACT_APP_SIT_UK_BBC_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_SIT_UK_BBC_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_SIT_UK_BBC_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_SIT_UK_BBC_ALGOLIA_QUERY_SUGGESTIONS,
	},
	uat: {
		appId: process.env.REACT_APP_UAT_UK_BBC_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_UAT_UK_BBC_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_UAT_UK_BBC_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_UAT_UK_BBC_ALGOLIA_QUERY_SUGGESTIONS,
	},
	prod: {
		appId: process.env.REACT_APP_PROD_UK_BBC_ALGOLIA_APP_ID,
		searchKey: process.env.REACT_APP_PROD_UK_BBC_ALGOLIA_SEARCH_KEY,
		searchIndexName: process.env.REACT_APP_PROD_UK_BBC_ALGOLIA_SEARCH_INDEX,
		querySuggestions: process.env.REACT_APP_PROD_UK_BBC_ALGOLIA_QUERY_SUGGESTIONS,
	},
};

