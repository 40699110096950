
import AutoComplete from './components/autocomplete';
import './sass/style.scss'; // Import regular stylesheet


function App() {
  return (
    <div className="App">
      <AutoComplete />
    </div>
  );
}

export default App;
