import {
  laithwaitesUK,
  sundaytimesUK,
  averysUK,
} from "./environment-variables/uk";
// import { laithwaitesUS, USWSJ } from "./environment-variables/us";
import {
  australianWineAU,
  winePeopleAU,
  virginWinesAU,
  velocityWinesAU,
} from "./environment-variables/au";
import { laithwaitesNZ } from "./environment-variables/nz";

let appId;
let searchKey;
let searchIndexName;
let querySuggestions;

const url = window.location.href;

// Used for localhost development
const localhostEnvVariables = {
  appId: process.env.REACT_APP_LOCAL_ALGOLIA_APP_ID,
  searchKey: process.env.REACT_APP_LOCAL_ALGOLIA_SEARCH_KEY,
  searchIndexName: process.env.REACT_APP_LOCAL_ALGOLIA_SEARCH_INDEX,
  querySuggestions: process.env.REACT_APP_LOCAL_ALGOLIA_QUERY_SUGGESTIONS,
};

export const getCountry = () => {
  let country;

  if (url.includes("averys.com") || url.includes("bbcgoodfoodwineclub.com")) {
    country = "UK";
  } else if (url.includes(".com.au")) {
    country = "AU";
  } else if (url.includes(".co.nz")) {
    country = "NZ";
  } else if (url.includes(".com")) {
    country = "US";
  } else {
    country = "UK";
  }

  return country;
};

const setCorrectAlgoliaKeys = (site) => {
  switch (true) {
    case url.includes("localhost"):
    case url.includes("webdev"):
    case url.includes("st."):
    case url.includes("uk-test."):
      ({ appId, searchKey, searchIndexName, querySuggestions } = site.webdev);
      break;
    case url.includes("uat."):
      ({ appId, searchKey, searchIndexName, querySuggestions } = site.uat);
      break;
    default:
      ({ appId, searchKey, searchIndexName, querySuggestions } = site.prod);
  }
};
// UK
if (["laithwaites.co.uk"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(laithwaitesUK);
}

if (["sundaytimeswineclub.co.uk"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(sundaytimesUK);
}

if (["averys.com"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(averysUK);
}

if (["bbcgoodfoodwineclub.com"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(laithwaitesUK);
}

// US
// if (["laithwaites.com"].some((domain) => url.includes(domain))) {
//   setCorrectAlgoliaKeys(laithwaitesUS);
// }

// if (["wsjwine.com"].some((domain) => url.includes(domain))) {
//   setCorrectAlgoliaKeys(USWSJ);
// }

// if (["nprwineclub.org"].some((domain) => url.includes(domain))) {
//   setCorrectAlgoliaKeys(laithwaitesUS);
// }

// New Zealand
if (
  ["laithwaiteswine.co.nz", "laithwaites.co.nz"].some((domain) =>
    url.includes(domain)
  )
) {
  setCorrectAlgoliaKeys(laithwaitesNZ);
}

// Australia
if (["theaustralianwine.com.au"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(australianWineAU);
}

if (["virginwines.com.au"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(virginWinesAU);
}

if (["velocitywinestore.com.au"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(velocityWinesAU);
}
if (["redemption.virginwines.com.au"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(velocityWinesAU);
}

if (["winepeople.com.au"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(winePeopleAU);
}

if (["laithwaites.com.au"].some((domain) => url.includes(domain))) {
  setCorrectAlgoliaKeys(winePeopleAU);
}

// Localhost
if (url.includes("localhost")) {
  ({
    appId,
    searchKey,
    searchIndexName,
    querySuggestions,
  } = localhostEnvVariables);
}

export const ALGOLIA_APP_ID = appId;
export const ALGOLIA_SEARCH_KEY = searchKey;
export const ALGOLIA_SEARCH_INDEX = searchIndexName;
export const ALGOLIA_QUERY_SUGGESTIONS = querySuggestions;
